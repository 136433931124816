import './App.css';
import React, { Component } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import web3 from "web3";
import logo from './assets/logo.png';
import detectEthereumProvider from '@metamask/detect-provider';
import banner from './assets/banner.png';
import oS from './assets/opensea.png';
import mM from './assets/MetaMask.png';

const s1 = () => {
	window.open("#");
}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let maxMintNFTs;
let onlyLeft;
let now;
let myTotalWL;

const so1 = () => {
	window.open("https://testnets.opensea.io/collection/testingbeta-v3");
}


// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

];



// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setMintRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_limit",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleMintLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "onlyLeftValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicSaleMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleMintLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0xD4D7Ff7f6d98A06AE768f7C5A5E27887cdbAb026";
let contract;
let wMintAmount = 1;
let totalWhitelistMinted;
let walletBalance;
let wStatus;
let publicSale;
let myNFTBalance;
let gasLimit = 285000;
let whitelistStatus;
let owner;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "",
		maxmint: '',
		stateNow: '',
		totalWhitelistSupply: '',
		myTotalWLNFTs: '',
		accountBalance: '',
		wlStatus: '',
		wlEvent: false,
		myNFTWallet: '',
		priceDisplay: ''

	}

	onSubmitMinus = async event => {
		event.preventDefault();
		console.log("MintAmount :" + mintAmount);
		mintAmount = mintAmount - 1;
		if (mintAmount < 1) {
			mintAmount = 1
		}
		this.setState({ nftMintingAmount: mintAmount });


		if (owner == account) {
			console.log("Owner's Wallet: " + owner);

			this.setState({ nftMintingAmount: mintAmount });


		} else {

			if (totalSupplyNFT < 300 && totalSupplyNFT >= 100) {

				onlyLeft = 10 - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * 40000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			} else {

				if (totalSupplyNFT == 10000 ) {

					onlyLeft = 10000 - totalSupplyNFT;
					mintAmount = onlyLeft;
					this.setState({ msg: "SOLD OUT" });

				} else{

				onlyLeft = 10 - publicSale;
				mintAmount = onlyLeft;

				valueOfNFTs = mintAmount * 80000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });
				}
			}	


		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });




		} else {

			if (totalSupplyNFT < 300 && totalSupplyNFT >= 100) {

				onlyLeft = 10 - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * 40000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			} else {

				if (totalSupplyNFT == 10000 ) {

					onlyLeft = 10000 - totalSupplyNFT;
					mintAmount = onlyLeft;
					this.setState({ msg: "SOLD OUT" });

				} else{

				onlyLeft = 10 - publicSale;
				mintAmount = onlyLeft;

				valueOfNFTs = mintAmount * 80000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });
				}
			}	


		}
	}

	max = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;
			mintAmount = onlyLeft;


			valueOfNFTs = mintAmount * 0;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });

		} else {

			if (totalSupplyNFT < 300 && totalSupplyNFT >= 100) {

				onlyLeft = 10 - publicSale;
				mintAmount = onlyLeft;

				valueOfNFTs = mintAmount * 40000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			} else {

				if (totalSupplyNFT == 10000 ) {

					onlyLeft = 10000 - totalSupplyNFT;
					mintAmount = onlyLeft;
					this.setState({ msg: "SOLD OUT" });

				} else{

				onlyLeft = 10 - publicSale;
				mintAmount = onlyLeft;

				valueOfNFTs = mintAmount * 80000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });
				}
			}			
			
		}

	}

	mint = async event => {
		event.preventDefault();


		/*(async () => {
	
	if (window.ethereum) {
			await window.ethereum.send('eth_requestAccounts');
			window.web3 = new web3(window.ethereum);
	
	
			contract = new window.web3.eth.Contract(ABI, address);
	
	
			let accounts = await window.web3.eth.getAccounts();
			account = accounts[0];
			this.setState({ walletAddress: account });
	
			console.log(Date.now());
			console.log("Wallet Address" + this.state.walletAddress);
		*/
		//......................................................//

		// https://medium.com/@ItsCuzzo/using-merkle-trees-for-nft-whitelists-523b58ada3f9
		//

		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);


		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));

	}

	walletbtn = async event => {
		event.preventDefault();

		const provider = await detectEthereumProvider();

		if (provider) {

			console.log('Ethereum successfully detected!');



			// From now on, this should always be true:
			// provider === window.ethereum

			// Access the decentralized web!

			//.....................................................................//

			(async () => {

				const chainId = 1;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainName: 'Ethereum Mainnet',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
									},
								],
							});
						}
					}
				}




				if (window.ethereum) {
					await window.ethereum.send('eth_requestAccounts');
					window.web3 = new web3(window.ethereum);
					//.....................................................................//
					//.....................................................................//


					contract = new window.web3.eth.Contract(ABI, address);


					let accounts = await window.web3.eth.getAccounts();
					account = accounts[0];
					this.setState({ walletAddress: account });

					console.log("Wallet Address" + this.state.walletAddress);

					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });

						console.log("Total Supply:" + totalSupplyNFT);	
					
						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

							if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;

									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {

									if (totalSupplyNFT < 300 && totalSupplyNFT >= 100) {
						
									//	onlyLeft = 10 - publicSale;
									//	mintAmount = onlyLeft;
										mintAmount = 1;
										valueOfNFTs = mintAmount * 40000000000000000;
						
										this.setState({ nftMintingAmount: mintAmount });
						
										this.setState({ totalValue: valueOfNFTs });
						
									} else {
						
										if (totalSupplyNFT == 10000 ) {
						
											onlyLeft = 10000 - totalSupplyNFT;
											mintAmount = onlyLeft;
											this.setState({ msg: "SOLD OUT" });
						
										} else{
						
										onlyLeft = 10 - publicSale;
										mintAmount = onlyLeft;
						
										valueOfNFTs = mintAmount * 80000000000000000;
						
										this.setState({ nftMintingAmount: mintAmount });
						
										this.setState({ totalValue: valueOfNFTs });
										}
									}			
									
								}
							} catch (err) {
						console.log("err: " + err);

					}

				}


			})();

			//.....................................................................//






			// Legacy providers may only have ethereum.sendAsync
			const chainId = await provider.request({
				method: 'eth_chainId'
			})

		} else {

			// if the provider is not detected, detectEthereumProvider resolves to null
			console.error('Please install MetaMask!');
			alert('A valid provider could not be found!');

		}


	}

	publicMint = async event => {
		event.preventDefault();


		console.log(this.state.walletAddress);

		try {

			owner = await contract.methods.owner().call();
			let totalGasLimit = String(gasLimit * mintAmount);



			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: String(totalGasLimit), from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: String(totalGasLimit), from: account, value: this.state.totalValue * 1 * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			}
		} catch (err) {

			console.log(err);

		}
	}


	render() {

		return (

			<div>
				<div class="allWrap">
					<div class="navBar">
						
						<div class="left">

							{this.state.walletAddress === '' ?
								(<form onSubmit={this.walletbtn}>
									<button class="tab1"><img class="mM" src={mM} /></button>
								</form>) : (<button class="wallet2" ><b>
									{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</b></button>)}

						</div>
					</div>
					<div class="section3mainDiv">

						<div class="section1">
						</div>

						<div class="section2">
							<div class="sec1Title">Mint Your NFTs</div>
							<div class="sec2Title">{this.state.totalSupply} / 10,000 NFTs Minted</div>


							<div class="line"></div>

							<div class="prisesSec1">
								<div>Amount</div>

								<div class="minting_count_button">

									<div>
										<form onSubmit={this.onSubmitMinus}>
											<button class="btnfos-0-2" type="submit">-</button>
										</form>
									</div>

									<div>
										<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
									</div>


									<div>
										<form onSubmit={this.onSubmitPlus}>
											<button class="btnfos-0-2" type="submit">+</button>
										</form>
									</div>


								</div>

								<div>
									<form onSubmit={this.max}>
										<button class="maxbtn">Max</button>
									</form>
								</div>


							</div>




							<div class="line"></div>

							<div class="prisesSec1">
								<div>My NFTs </div>
								{this.state.walletAddress === '' ?
									(<div>Not Connected</div>) :
									(<div>{this.state.myNFTWallet}/10</div>)}
							</div>

							<div class="line"></div>
							<div class="mintButtonDiv">
								<form onSubmit={this.publicMint}>

									<button class="mintButton" type="submit">
										Mint for {this.state.totalValue / 1000000000000000000} ETH</button>
								</form>
							</div>
							<div>

								{this.state.statusError ? (
									<div class="errorMessage">
										<div >Sorry, something went wrong</div>
									</div>)
									: null}

								{this.state.statusLoading ? (
									<div class="loadingContainer">
										<div>
											<div class="loadingText"> Minting Your Alpha Bunny</div>
										</div>

									</div>)
									: null}

								{this.state.success ? (

									<div class="successfully">MINTING SUCCESSFUL! </div>)
									: null}

							</div>
						</div>

						<div class="section3">

						</div>
					</div>
				</div >

			</div>)
	}
}

export default App;
